import styles from './My.module.scss';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bankAccount from '../../assets/images/bank-account.png';
import homeAddress from '../../assets/images/home-address.png';
import history from '../../assets/images/history-grey.png';
import key from '../../assets/images/key.png';
import supportIcon from '../../assets/images/cskhicon.png';

const axios = require('axios').default;

const cx = classNames.bind(styles);

function ney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function My() {
    let [user, setUser] = useState([]);
    let [mission, setMission] = useState([]);
    let [nameUserBank, setNameUserBank] = useState();

    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setUser(data.data[0]);
                    setMission(data.mission);
                    if (data.userBank) {
                        setNameUserBank(data.userBank.name_u_bank);
                    } else setNameUserBank(data.data[0].username);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    return (
        <div className={cx('account')}>
            <div className={cx('account-header')}>
                <div className={cx('account-username')}>{nameUserBank}</div>
                <div className={cx('account-level')}>{user.name_level}</div>
                <div className={cx('account-id')}>
                    ID: <p className={cx('accout-id-number')}>{user.id_user}</p>
                </div>
            </div>
            <div className={cx('session')}>
                <Link to="/my/banking" className={cx('session-item')}>
                    <div className={cx('session-icon')}>
                        <img src={bankAccount} className={cx('session-icon-item')} alt="" />
                    </div>
                    <div className={cx('session-desc')}>Tài khoản ngân hàng</div>
                </Link>
                <Link to="/my/guide" className={cx('session-item')}>
                    <div className={cx('session-icon')}>
                        <svg
                            className={cx('session-icon-item')}
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1z"
                            ></path>
                        </svg>
                    </div>
                    <div className={cx('session-desc')}>Thành viên thân thiết</div>
                </Link>
                <Link to="/my/address" className={cx('session-item')}>
                    <div className={cx('session-icon')}>
                        <img src={homeAddress} className={cx('session-icon-item')} alt="" />
                    </div>
                    <div className={cx('session-desc')}>Địa chỉ cư trú</div>
                </Link>
            </div>
            <div className={cx('session')}>
                <Link to="/order/index" className={cx('session-item')}>
                    <div className={cx('session-icon')}>
                        <img src={history} className={cx('session-icon-item')} alt="" />
                    </div>
                    <div className={cx('session-desc')}>Lịch sử đơn hàng</div>
                </Link>
                <Link to="/order/mission" className={cx('session-item')}>
                    <div className={cx('session-icon')}>
                        <svg
                            className={cx('session-icon-item')}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            dataicon="shopping-cart"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path d="M922.9 701.9H327.4l29.9-60.9 496.8-.9c16.8 0 31.2-12 34.2-28.6l68.8-385.1c1.8-10.1-.9-20.5-7.5-28.4a34.99 34.99 0 00-26.6-12.5l-632-2.1-5.4-25.4c-3.4-16.2-18-28-34.6-28H96.5a35.3 35.3 0 100 70.6h125.9L246 312.8l58.1 281.3-74.8 122.1a34.96 34.96 0 00-3 36.8c6 11.9 18.1 19.4 31.5 19.4h62.8a102.43 102.43 0 00-20.6 61.7c0 56.6 46 102.6 102.6 102.6s102.6-46 102.6-102.6c0-22.3-7.4-44-20.6-61.7h161.1a102.43 102.43 0 00-20.6 61.7c0 56.6 46 102.6 102.6 102.6s102.6-46 102.6-102.6c0-22.3-7.4-44-20.6-61.7H923c19.4 0 35.3-15.8 35.3-35.3a35.42 35.42 0 00-35.4-35.2zM305.7 253l575.8 1.9-56.4 315.8-452.3.8L305.7 253zm96.9 612.7c-17.4 0-31.6-14.2-31.6-31.6 0-17.4 14.2-31.6 31.6-31.6s31.6 14.2 31.6 31.6a31.6 31.6 0 01-31.6 31.6zm325.1 0c-17.4 0-31.6-14.2-31.6-31.6 0-17.4 14.2-31.6 31.6-31.6s31.6 14.2 31.6 31.6a31.6 31.6 0 01-31.6 31.6z"></path>
                        </svg>
                    </div>
                    <div className={cx('session-desc')}>Tìm kiếm đơn hàng</div>
                </Link>
                {/* <div className={cx('session-item')}>
                    <div className={cx('session-icon')}>
                        <svg
                            className={cx('session-icon-item')}
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1z"
                            ></path>
                        </svg>
                    </div>
                    <div className={cx('session-desc')}>Doanh thu đại lý</div>
                </div> */}
            </div>
            <div className={cx('session')}>
                <div className={cx('session-item')}>
                    <div className={cx('session-icon')}>
                        <svg
                            className={cx('session-icon-item')}
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 640 512"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M352 288h-16v-88c0-4.42-3.58-8-8-8h-13.58c-4.74 0-9.37 1.4-13.31 4.03l-15.33 10.22a7.994 7.994 0 0 0-2.22 11.09l8.88 13.31a7.994 7.994 0 0 0 11.09 2.22l.47-.31V288h-16c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h64c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 192c-53.02 0-96-50.15-96-112 0-61.86 42.98-112 96-112s96 50.14 96 112c0 61.87-43 112-96 112zm272 32h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z"></path>
                        </svg>
                    </div>
                    <Link to="/my/history-recharge-withdraw">
                        <div className={cx('session-desc')}>Lịch sử giao dịch</div>
                    </Link>
                </div>
                <div className={cx('session-item')}>
                    <div className={cx('session-icon')}>
                        <img src={key} className={cx('session-icon-item')} alt="" />
                    </div>
                    <Link to="/my/edit-password">
                        <div className={cx('session-desc')}>Mật khẩu đăng nhập</div>
                    </Link>
                </div>
                <div className={cx('session-item')}>
                    <div className={cx('session-icon')}>
                        <svg
                            className={cx('session-icon-item')}
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"></path>
                        </svg>
                    </div>
                    <Link to="/my/edit-password-transaction">
                        <div className={cx('session-desc')}>Mật khẩu giao dịch</div>
                    </Link>
                </div>
            </div>
            <div className={cx('session')}>
                {/* https://tawk.to/chat/644e5b404247f20fefee944d/1gv918tfb */}
                <Link
                    to="https://tawk.to/chat/644e5b404247f20fefee944d/1gv918tfb"
                    className={cx('session-item')}
                    target="blank"
                >
                    <div className={cx('session-icon')}>
                        <img src={supportIcon} className={cx('session-icon-item')} alt="" />
                    </div>
                    <div className={cx('session-desc')}>Dịch vụ CSKH</div>
                </Link>
                <Link to="/system/support" className={cx('session-item')}>
                    <div className={cx('session-icon')}>
                        <svg
                            className={cx('session-icon-item')}
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z"></path>
                        </svg>
                    </div>
                    <div className={cx('session-desc')}>Hệ thống Rakuten</div>
                </Link>
            </div>
            <button className={cx('account-footer')}>
                <div className={cx('footer-icon')}>
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 0 1-112.7 75.9A352.8 352.8 0 0 1 512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 0 1-112.7-75.9 353.28 353.28 0 0 1-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 7.9 7.9 15.3 16.1 22.4 24.5 3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82 271.7 82.6 79.6 277.1 82 516.4 84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7 3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6z"></path>
                    </svg>
                </div>
                <div
                    onClick={() => {
                        localStorage.removeItem('auth');
                        window.location.href = '/account/login';
                    }}
                    className={cx('footer-desc')}
                >
                    Đăng xuất
                </div>
            </button>
        </div>
        // <div className="bg-[#f6f6f6]">
        //     <div className="min-h-[770px]">
        //         <div className="fixed top-0 w-[100%] min-h-[56px] bg-[#fff] flex items-center">
        //             <Link to="/my/settings">
        //                 <img
        //                     className={cx('border-my-info', { 'w-[44px] h-[44px] m-[5px] rounded-full': true })}
        //                     src="https://i.imgur.com/hDXTD9v.png"
        //                     alt=""
        //                 />
        //             </Link>
        //             <div className="flex flex-col ml-[16px]">
        //                 <div className="flex">
        //                     <p className="text-[#ff976a] font-bold">{user.username || '..........'}</p>
        //                     <div className="">
        //                         <p className="pl-[5px] font-bold text-[#fbc531]">
        //                             {user.roses_user && user.roses_user.toUpperCase()}
        //                         </p>
        //                     </div>
        //                 </div>
        //                 <div className="text-xl">Mã mời: {user.id_user || '..........'}</div>
        //             </div>
        //         </div>
        //         <div className="bg-[#fff] mx-auto mt-[60px] p-[10px] w-[calc(100%)]">
        //             <div
        //                 className="my-[20px] border-l-[4px] h-[36px] flex items-center"
        //                 style={{ borderColor: '#ff3c61' }}
        //             >
        //                 <p className="ml-[10px] font-bold text-[16px]">Vốn lưu động</p>
        //             </div>
        //             <div className="flex justify-between">
        //                 <p className="text-xl mb-2">Số dư của tôi</p>
        //                 <Link className="text-[#ffaa03] font-bold text-xl" to="/my/financial-details">
        //                     Chi tiết tài chính
        //                 </Link>
        //             </div>
        //             <div className="font-bold text-[#ffaa03] text-4xl">{formatMoney(user.money) || '0'}VND</div>

        //             <div className="mt-[10px]">
        //                 <p className="text-xl mb-2">Số dư hiện tại đang bị đóng băng</p>
        //                 <div className="font-bold text-[#ffaa03] text-4xl">{formatMoney(mission.pending+user.dongbangtk)}VND</div>
        //             </div>
        //             <div className="flex justify-between mt-[10px]">
        //                 <Link to="/recharge" className="w-full py-2 pr-2">
        //                     <div
        //                         className="flex-1 p-3 text-center text-[#fff] text-3xl lg:text-4xl"
        //                         style={{
        //                             borderRadius: '50px',
        //                             background: `linear-gradient(304deg,#74c8fb,#1ba8ff)`,
        //                         }}
        //                     >
        //                         nạp tiền
        //                     </div>
        //                 </Link>
        //                 <Link to="/withdraw" className="w-full py-2 pl-2">
        //                     <div
        //                         className="flex-1 p-3 text-center font-sm text-[#ff3c61] text-3xl lg:text-4xl border border-sky-500"
        //                         style={{
        //                             borderRadius: '50px',
        //                             background: `#fff`,
        //                             borderColor: '#ff3c61',
        //                         }}
        //                     >
        //                         rút tiền
        //                     </div>
        //                 </Link>
        //             </div>
        //         </div>
        //         <div className="w-[calc(100%)] mx-auto bg-[#fff] px-[10px] py-[20px]">
        //             <div className="flex justify-between">
        //                 <div className="text-left">
        //                     <p className="text-xl text-[#005652]">Đơn hàng hôm nay</p>
        //                     <div className="text-3xl mt-[2px] text-[#ff976a] font-bold">
        //                         {formatMoney(mission.amountToday)}
        //                     </div>
        //                 </div>
        //                 <div className="text-center">
        //                     <p className="text-xl text-[#005652]">Hoa hồng hôm nay</p>
        //                     <div className="text-3xl mt-[2px] text-[#ff976a] font-bold">
        //                         {formatMoney(mission.resultToday)}VND
        //                     </div>
        //                 </div>
        //                 <div className="text-right">
        //                     <p className="text-xl text-[#005652]">Phần thưởng hôm nay</p>
        //                     <div className="text-3xl mt-[2px] text-[#ff976a] font-bold">0VND</div>
        //                 </div>
        //             </div>
        //             <div className="flex justify-between">
        //                 <div className="text-left">
        //                     <p className="text-xl text-[#005652]">Tất cả đơn hàng</p>
        //                     <div className="text-3xl mt-[2px] text-[#ff976a] font-bold">
        //                         {formatMoney(mission.amount)}
        //                     </div>
        //                 </div>
        //                 <div className="text-center">
        //                     <p className="text-xl text-[#005652]">Tổng hoa hồng</p>
        //                     <div className="text-3xl mt-[2px] text-[#ff976a] font-bold">
        //                         {formatMoney(mission.result)}VND
        //                     </div>
        //                 </div>
        //                 <div className="text-right">
        //                     <p className="text-xl text-[#005652]">Tổng phần thưởng</p>
        //                     <div className="text-3xl mt-[2px] text-[#ff976a] font-bold">0VND</div>
        //                 </div>
        //             </div>
        //         </div>

        //         <div className="w-[calc(100%)] mx-auto bg-[#fff]  px-[10px] mt-[20px] pt-[10px]">
        //             <div
        //                 className="my-[20px] border-l-[4px] h-[36px] flex items-center"
        //                 style={{ borderColor: '#ff3c61' }}
        //             >
        //                 <p className="ml-[10px] font-bold text-[16px]">Thao tác nhanh</p>
        //             </div>
        //             <div className="flex flex-wrap justify-left">
        //                 <Link to="/order/index" className="w-[33%] p-[5px] mb-[25px]">
        //                     <img className="w-[36px] h-[36px] mx-auto" src="https://i.imgur.com/dqfvPrF.png" alt="" />
        //                     <p className="mt-[10px] font-medium text-center">Đơn hàng</p>
        //                 </Link>
        //                 <Link to="/my/settings" className="w-[33%] p-[5px] mb-[25px]">
        //                     <img className="w-[36px] h-[36px] mx-auto" src="https://i.imgur.com/DVe1A5l.png" alt="" />
        //                     <p className="mt-[10px] font-medium text-center">Sửa đổi</p>
        //                 </Link>
        //                 <Link to="/my/guide" className="w-[33%] p-[5px] mb-[25px]">
        //                     <img className="w-[36px] h-[36px] mx-auto" src="https://i.imgur.com/u8yYuax.png" alt="" />
        //                     <p className="mt-[10px] font-medium text-center">Hướng dẫn</p>
        //                 </Link>
        //                 <Link to="/my/service" className="w-[33%] p-[5px] mb-[25px]">
        //                     <img className="w-[36px] h-[36px] mx-auto" src="https://i.imgur.com/FTXlbze.png" alt="" />
        //                     <p className="mt-[10px] font-medium text-center">Dịch vụ</p>
        //                 </Link>
        //                 <Link to="/support" className="w-[33%] p-[5px] mb-[25px]">
        //                     <img className="w-[36px] h-[36px] mx-auto" src="https://i.imgur.com/jCouZkJ.png" alt="" />
        //                     <p className="mt-[10px] font-medium text-center">CSKH</p>
        //                 </Link>
        //                 <Link to="/my/historyrut" className="w-[33%] p-[5px] mb-[25px]">
        //                     <img className="w-[36px] h-[36px] mx-auto" src="https://i.imgur.com/dqfvPrF.png" alt="" />
        //                     <p className="mt-[10px] font-medium text-center">LS Rút Tiền</p>
        //                 </Link>
        //             </div>
        //         </div>
        //     </div>
        //     <ToastContainer
        //         position="top-right"
        //         autoClose={1000}
        //         hideProgressBar={false}
        //         newestOnTop={false}
        //         closeOnClick
        //         rtl={false}
        //         pauseOnFocusLoss
        //         draggable
        //         pauseOnHover
        //     />
        // </div>
    );
}

export default My;
