import home from '../../../../assets/images/home2.png';
import home2 from '../../../../assets/images/home.png';
import mission from '../../../../assets/images/mission.png';
import mission2 from '../../../../assets/images/mission2.png';
import my from '../../../../assets/images/my.png';
import my2 from '../../../../assets/images/myHL.png';
import home_coco from '../../../../assets/images/home-coco.png';
import system from '../../../../assets/images/system.png';
import trade from '../../../../assets/images/trade.png';
import history from '../../../../assets/images/history.png';
import account from '../../../../assets/images/account.png';
import { Link, Outlet } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';
import './Navbar.css';

function Navbar(props) {
    let link = props.link;
    let [homeN, setHome] = useState(link === '/' ? true : false);
    let [historyN, setHistory] = useState(link === '/history' ? true : false);
    let [myN, setMy] = useState(link === '/my' ? true : false);
    let [systemN, setSystem] = useState(link === '/system' ? true : false);
    let [tradeN, setTrade] = useState(link === '/trade' ? true : false);

    useEffect(() => {
        if (link.indexOf('/order/index') >= 0) {
            setHome(false);
            setHistory(true);
            setMy(false);
            setTrade(false);
            setSystem(false);
        } else if (link === '/my') {
            setHome(false);
            setHistory(false);
            setMy(true);
            setTrade(false);
            setSystem(false);
        } else if (link === '/system') {
            setHome(false);
            setHistory(false);
            setMy(false);
            setTrade(false);
            setSystem(true);
        } else if (link === '/order/mission') {
            setHome(false);
            setHistory(false);
            setMy(false);
            setTrade(true);
            setSystem(false);
        } else if (link === '/') {
            setHome(true);
            setHistory(false);
            setMy(false);
            setTrade(false);
            setSystem(false);
        }
    }, [link]);

    return (
        <nav className="w-full navbar__footer fixed bottom-0 left-0 z-[49]">
            <div className="flex justify-center items-center navbar__footer--container p-1">
                <Link to="/" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2">
                        <div className="bar-item__icon">
                            <i className="flex justify-center">
                                <img src={home_coco} alt="Home" className="w-8 h-8 sepia-0" />
                            </i>
                        </div>
                        <div
                            className="text-xl lg:text-2xl mt-[2px]"
                            style={{ color: `rgb(${!homeN ? '255,255,255' : '245, 197, 86'})` }}
                        >
                            Trang chủ
                        </div>
                    </div>
                </Link>

                <Link to="/system" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2">
                        <div className="bar-item__icon">
                            <i className="flex justify-center">
                                <img src={system} alt="System" className="w-8 h-8 sepia-0" />
                            </i>
                        </div>
                        <div
                            className="text-xl lg:text-2xl mt-[2px]"
                            style={{ color: `rgb(${!systemN ? '255,255,255' : '245, 197, 86'})` }}
                        >
                            Hệ thống
                        </div>
                    </div>
                </Link>
                <Link to="/order/mission" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2">
                        <div className="bar-item__icon">
                            <i className="flex justify-center">
                                <img src={trade} alt="Trade" className="w-8 h-8 sepia-0" />
                            </i>
                        </div>
                        <div
                            className="text-xl lg:text-2xl mt-[2px]"
                            style={{ color: `rgb(${!tradeN ? '255,255,255' : '245, 197, 86'})` }}
                        >
                            Giao dịch
                        </div>
                    </div>
                </Link>
                <Link to="/order/index" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2">
                        <div className="bar-item__icon">
                            <i className="flex justify-center">
                                <img src={history} alt="History" className="w-8 h-8" />
                            </i>
                        </div>
                        <div
                            className="text-xl lg:text-2xl mt-[2px]"
                            style={{ color: `rgb(${!historyN ? '255,255,255' : '245, 197, 86'})` }}
                        >
                            Lịch sử
                        </div>
                    </div>
                </Link>

                <Link to="/my" className="flex-1 flex justify-center text-center">
                    <div className="flex-1 flex justify-center text-center flex-col py-2">
                        <div className="bar-item__icon">
                            <i className="flex justify-center">
                                <img src={my} alt="home" className="w-8 h-8" />
                            </i>
                        </div>
                        <div
                            className="text-xl lg:text-2xl mt-[2px]"
                            style={{ color: `rgb(${!myN ? '255,255,255' : '245, 197, 86'})` }}
                        >
                            Tài khoản
                        </div>
                    </div>
                </Link>
            </div>
            <Outlet />
        </nav>
    );
}

export default memo(Navbar);
