import classNames from 'classnames/bind';
import styles from './Login.module.scss';
import { isMobile } from 'react-device-detect';

import logo from '../../assets/images/Shopee.svg.webp';
import backgroundMain from '../../assets/images/backgroundMain.png';
import logo_shopee from '../../assets/images/logo_shopee.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

function Account(props) {
    let navigate = useNavigate();

    // if (isMobile) {
    //     return (
    //         <div>
    //             <div id="account">
    //                 <header className={cx('header-mobile')}>
    //                     <div className={cx('header-left')}>
    //                         <div onClick={() => navigate(-1)}>
    //                             <FontAwesomeIcon className={cx('fa-arrow-left')} icon={faArrowLeft} />
    //                         </div>
    //                         <div className={cx('header-title')}>{props.type}</div>
    //                     </div>
    //                     <a
    //                         href="https://help.shopee.vn/portal"
    //                         className={cx('header-helper')}
    //                         target="_blank"
    //                         rel="noopener noreferrer"
    //                     >
    //                         Cần trợ giúp?
    //                     </a>
    //                 </header>
    //                 <main className={cx('container')}>
    //                     <div className={cx('main-content-mobile')}>
    //                         <form>
    //                             <div className={cx('form-content-mobile')}>
    //                                 <div
    //                                     className={cx('logo-shopee-mobile')}
    //                                     style={{ backgroundImage: `url(${logo_shopee})` }}
    //                                 ></div>
    //                                 {/* <div className={cx('form-title')}>{props.type}</div> */}
    //                                 {props.children}
    //                             </div>
    //                         </form>
    //                     </div>
    //                 </main>
    //             </div>
    //         </div>
    //     );
    // }
    return (
        <div id="account">
            {/* <header className={cx('header')}>
                <div className={cx('header-left')}>
                    <div className={cx('logo')}>
                        <img src={logo} alt="Acocoshop" />
                    </div>
                    <div className={cx('header-title')}>{props.type}</div>
                </div>
                <a
                    href="https://help.shopee.vn/portal"
                    className={cx('header-helper')}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Bạn cần giúp đỡ?
                </a>
            </header> */}
            <main className={cx('container')}>
                <div className={cx('main-content')}>
                    {/* <div className={cx('form-title')}></div> */}
                    {props.children}
                </div>
            </main>
        </div>
    );
}

export default Account;
