import React, { useState, useEffect } from 'react';

function ListHome(drops) {
    let amount = drops.amount;

    const [random1s, setRandom1s] = useState(true);

    let level = ['VIP1', 'VIP2', 'VIP3', 'VIP4', 'VIP5', 'VIP6'];
    let numberPhone = ['036', '034', '094', '092', '096', '078', '085', '093', '092', '091', '038', '037', '039'];

    function random(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function name() {
        return numberPhone[random(0, 11)] + '***' + random(1000, 9999);
    }

    function formatMoney(money = 0) {
        return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    useEffect(() => {
        setInterval(() => {
            setRandom1s((prev) => !prev);
        }, 5000);
    }, []);

    return (
        <ul>
            {Array(amount)
                .fill({})
                .map((data, index) => {
                    return (
                        <li className="sc-cxpSdN dNsaPg" key={index}>
                            <div className="dNsaPg-detail">
                                <p>Đại lý: </p>
                                <span> {name()}</span>
                            </div>
                            <div className="dNsaPg-detail">
                                <p>Lợi nhuận: </p>
                                <span style={{ color: 'rgb(86, 177, 127)' }}>${formatMoney(random(100, 10000))}</span>
                            </div>
                            {/* <div className="flex-1 flex items-center">
                                <img className="md:max-w-[150px]" src="https://i.imgur.com/MfRrb7f.png" alt="img" />
                                <p className="text-xl ml-[5px] md:text-2xl text-[#005652]">{level[random(0, 1)]}</p>
                            </div>
                            <div className="flex-1 flex items-center">
                                <img className="md:max-w-[150px]" src="https://i.imgur.com/VE7rgl1.png" alt="img" />
                                <p className="text-xl ml-[5px] md:text-2xl text-[#0dc253] font-light">{name()}</p>
                            </div>
                            <div className="flex-2 flex items-center">
                                <p className="min-w-[25px] text-xl md:text-2xl text-[#ffa900] font-medium">
                                    +{random(10000, 1000000)}
                                </p>
                            </div> */}
                        </li>
                    );
                })}
        </ul>
    );
}

export default ListHome;
