import Home from '../pages/Home';
import My from '../pages/My';
import Order from '../pages/Order';
import Login from '../pages/Account/Login';
import Register from '../pages/Account/Register';
import ComfirmOrder from '../pages/Order/ComfirmOrder';
import FinancialDetails from '../pages/My/FinancialDetails';
import HistoryRut from '../pages/Order/HistoryRut';
import History from '../pages/Order/History';
import Settings from '../pages/My/Settings';
import Guide from '../pages/My/Guide';
import Service from '../pages/My/Service';
import EditPassword from '../pages/My/EditPassword';
import EditPwTransaction from '../pages/My/EditPwTransaction';
import MyBank from '../pages/My/MyBank';
import Withdraw from '../pages/My/Withdraw';
import Recharge from '../pages/My/Recharge';
import Address from '../pages/My/Address';
import FormRecharge from '../pages/My/FormRecharge';
import OrderRedirect from '../pages/Order/OrderRedirect';
import NotFound from '../pages/NotFound';
import Support from '../pages/My/Support';
import System from '../pages/System';
import SystemInformation from '../pages/System/Support';
import ChatSupport from '../pages/My/ChatSupport';

// Public Router

let defaultTitle = 'Acocoshop Việt Nam';

const publicRoutes = [
    // Trang chủ
    { path: '/', title: 'Trang chủ', component: Home },

    // Hệ thông
    { path: '/system', title: 'Hệ thống', component: System },
    { path: '/system/support', title: 'Hỗ trợ', component: SystemInformation },

    // Nạp rút tiền
    { path: '/recharge', title: `Nạp tiền | ${defaultTitle}`, component: Recharge },
    { path: '/recharge/:id', title: `Nạp tiền | ${defaultTitle}`, component: FormRecharge },
    { path: '/withdraw', title: `Rút tiền | ${defaultTitle}`, component: Withdraw },

    // Order
    // { path: '/support', title: 'Hỗ trợ khách hàng', component: ChatSupport },
    { path: '/order/mission', title: 'Nhiệm vụ', component: OrderRedirect },
    { path: '/order/mission/:id_mission', title: 'Giao dịch', component: Order },
    { path: '/order/index', title: 'Lịch sử đặt hàng', component: History },
    { path: '/order/confirm/:id', title: `Comfirm Order | ${defaultTitle}`, component: ComfirmOrder },
    // My
    { path: '/my', title: 'Của tôi', component: My },
    { path: '/my/settings', title: 'Cài đặt', component: Settings },
    { path: '/my/address', title: 'Địa chỉ', component: Address },
    { path: '/my/guide', title: 'Hướng dẫn', component: Guide },
    { path: '/my/service', title: 'Dịch vụ', component: Service },
    { path: '/my/edit-password', title: 'Đổi mật khẩu', component: EditPassword },
    { path: '/my/banking', title: 'Liên kết ngân hàng', component: MyBank },
    {
        path: '/my/edit-password-transaction',
        title: 'Đổi mật khẩu giao dịch',
        component: EditPwTransaction,
    },
    {
        path: '/my/financial-details',
        title: `Financial details | ${defaultTitle}`,
        component: FinancialDetails,
    },
    {
        path: '/my/history-recharge-withdraw',
        title: `Lịch sử nạp rút | ${defaultTitle}`,
        component: HistoryRut,
    },
    // Account
    {
        path: '/account/login',
        title: `Đăng nhập tài khoản | ${defaultTitle}`,
        component: Login,
        layout: null,
    },
    { path: '/account/register', title: `Đăng ký ngay | ${defaultTitle}`, component: Register, layout: null },
    { path: '*', title: `NotFound | ${defaultTitle}`, component: NotFound, layout: null },
];
// Private Router
const privateRoutes = [];

export { publicRoutes, privateRoutes };
