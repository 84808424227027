import Header from '../../components/Layout/components/Header';
import styles from './My.module.scss';
import classNames from 'classnames/bind';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
const axios = require('axios').default;

const cx = classNames.bind(styles);
const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
function MyBank({ title }) {
    document.title = title;
    let [nameOnwerBank, setNameOnwerBank] = useState('');
    let [stk, setStk] = useState('');
    let [nameBank, setNameBank] = useState('');
    let [sdt, setSdt] = useState('');
    let [check, setCheck] = useState('');
    const [address, setAddress] = useState('');
    const [isBankConnected, setIsBankConnected] = useState(false);
    const [isAdress, setIsAdress] = useState(false);
    const [user, setUser] = useState('');

    const handleAddAddress = () => {
        if (!address) return toast.error('Vui lòng nhập đầy đủ thông tin !', { theme: 'light' });
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/user/addaddress`,
                { address },
                {
                    headers,
                },
            )
            .then(function (response) {
                let data = response.data;
                if (data.data === 1) toast.success('Thêm địa chỉ thành công !', { theme: 'light' });
                if (data.data === 2) toast.success('Sửa ngân hàng thành công !', { theme: 'light' });
                if (data.status) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    useEffect(() => {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    if (data.data[0].address) {
                        setIsAdress(true);
                        setUser(data.data[0]);
                    }
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/user/banking`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.data.recharge.length > 0) {
                    setCheck(data.data);
                    setIsBankConnected(true);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    return (
        <div className={cx('edit-password', { '': true })}>
            <div className="form-edit-password p-[20px]">
                <div className={cx('bank-header')}>Địa chỉ cư trú</div>
                <div className={cx('form-group', { '': true })}>
                    <label className={cx('bank-title')}>Họ và tên chủ khoản</label>
                    <input
                        disabled={isBankConnected}
                        value={
                            Array.isArray(check.recharge) && check.recharge.length > 0
                                ? check.recharge[0].name_u_bank
                                : nameOnwerBank
                        }
                        onChange={(e) => setNameOnwerBank(e.target.value)}
                        className="w-[100%]"
                        placeholder="Họ và tên chủ khoản"
                    />
                </div>

                <div className={cx('form-group', { '': true })}>
                    <label className={cx('bank-title')}>Tài khoản</label>
                    <input
                        disabled={isBankConnected}
                        value={
                            Array.isArray(check.recharge) && check.recharge.length > 0 ? check.recharge[0].phone : sdt
                        }
                        onChange={(e) => setSdt(e.target.value)}
                        className="w-[100%]"
                        placeholder="Tài khoản"
                    />
                </div>
                <div className={cx('form-group', { '': true })}>
                    <label className={cx('bank-title')}>Địa chỉ cư trú hiện tại</label>
                    <input
                        disabled={isAdress}
                        defaultValue={user.address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="w-[100%]"
                        placeholder="Nhập Địa chỉ cư trú"
                    />
                </div>

                {/* {Array.isArray(check.recharge) && check.recharge.length === 0 && ( */}
                {!isAdress && (
                    <div onClick={() => handleAddAddress()} className={cx('form-group', { 'text-center': true })}>
                        <div className={cx('btn-submit', { 'text-[#fff]': true })}>XÁC NHẬN</div>
                    </div>
                )}
                {/* )} */}
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default MyBank;
