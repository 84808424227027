import classNames from 'classnames/bind';

import logo from '../../assets/images/logo_lagi_8_resize.jfif';
import logoEbay from '../../assets/images/rakuten1.png';
import { Link } from 'react-router-dom';
import styles from './System.module.scss';
const cx = classNames.bind(styles);

function System() {
    return (
        <div className={cx('system')}>
            <h3 className={cx('title')}>Hệ thống Rakuten</h3>
            <h3 className={cx('sub-title')}>Tìm hiểu về hệ thống Rakuten</h3>
            <div className={cx('banner')}>
                <div className={cx('banner-item')}>
                    <div className={cx('banner-inner')}>
                        <div className={cx('banner-inner-logo')}>
                            <img src={logoEbay} className={cx('banner-logo')} alt="" />
                        </div>
                        <div className={cx('banner-content')}>
                            <div className={cx('banner-title')}>Giới thiệu về hệ thống</div>
                            <h3 className={cx('banner-company')}>RAKUTEN</h3>
                        </div>
                    </div>
                </div>
                <div className={cx('banner-item')}>
                    <div className={cx('banner-inner')}>
                        <div className={cx('banner-inner-logo')}>
                            <img src={logoEbay} className={cx('banner-logo')} alt="" />
                        </div>
                        <div className={cx('banner-content')}>
                            <div className={cx('banner-title')}>Thành viên thân thiết</div>
                            <h3 className={cx('banner-company')}>RAKUTEN</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx('infomation')}>
                <Link to="/system/support" className={cx('infomation-item')}>
                    Hồ sơ công ty
                </Link>
                <Link to="/system/support" className={cx('infomation-item')}>
                    Hợp tác đại lý
                </Link>
                <Link to="/system/support" className={cx('infomation-item')}>
                    Thành viên RAKUTEN
                </Link>
                <Link to="/system/support" className={cx('infomation-item')}>
                    Đầu tư và thu nhập
                </Link>
                <Link to="/system/support" className={cx('infomation-item')}>
                    Hoạt động khuyến mãi
                </Link>
                <Link to="/system/support" className={cx('infomation-item')}>
                    Điều kiện mở tài khoản
                </Link>
                <Link to="/system/support" className={cx('infomation-item')}>
                    Điều kiện & Điều khoản
                </Link>
                <Link to="/system/support" className={cx('infomation-item')}>
                    Quyền riêng tư và bảo mật
                </Link>
            </div>
        </div>
    );
}

export default System;
