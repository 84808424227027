import React from 'react';
import Crisp from 'react-crisp';

function ChatSupport() {
    return (
        <>
            <Crisp crispWebsiteId="794b0445-5ba9-4ce0-abda-a5f54ae1784b" />
        </>
    );
}

export default ChatSupport;
