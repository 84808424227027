import Header from '../../components/Layout/components/Header';
import styles from './Order.module.scss';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleCheck, faMagnifyingGlass, faX, faXmark, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import ProductBox from './ProductBox';
import { useEffect, useRef, useState } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faBuffer } from '@fortawesome/free-brands-svg-icons';
import cocoShop from '../../assets/images/cocoshop.jpg';
import banner3 from '../../assets/images/banner3.jpg';
import './Order.css';

import audio from '../../assets/audio/hongbao.mp3';
const axios = require('axios').default;

const cx = classNames.bind(styles);

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

const formatter = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
});

const normalFormatter = new Intl.NumberFormat();

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function Order() {
    const { id_mission } = useParams();
    const newId = useRef(id_mission);
    let [box, setBox] = useState(false);
    let [update, setUpdate] = useState(false);
    let [go1, setGo1] = useState(false);
    let [go2, setGo2] = useState(false);
    let [width, setWidth] = useState(0);
    let [myMission, setMyMission] = useState([{}]);

    const [progress, setProgress] = useState(0);
    const [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0, roses: 0 }],
        moneyEarn: { moneyEarnPreDay: 0, moneyEarnToday: 0 },
        mission: {
            amount: 0,
            result: 0,
            amountToday: 0,
        },
    });

    const navigate = useNavigate();

    const mucVonDauTu = [
        { title: 'PHỔ THÔNG', von: 200, loi_nhuan: 0.4 },
        { title: 'TIỂU THƯƠNG', von: 1000, loi_nhuan: 0.5 },
        { title: 'THƯƠNG GIA', von: 5000, loi_nhuan: 0.6 },
        { title: 'ĐẠI LÝ Rakuten', von: 10000, loi_nhuan: 0.7 },
        { title: 'DOANH NGHIỆP', von: 50000, loi_nhuan: 0.9 },
    ];

    const playAudio = () => {
        new Audio(audio).play();
    };

    const handAnimateMission = async () => {
        await sleep(200);
        setWidth(10);
        await sleep(500);
        setWidth(30);
        await sleep(1500);
        setWidth(80);
        await sleep(600);
        setWidth(100);
        await sleep(1400);
        setGo1(true);
        await sleep(2200);
        setGo2(true);
        await sleep(1100);
        handSendMission();
    };

    const handSendMission = async () => {
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/mission/new`,
                { level: id_mission },
                {
                    headers,
                },
            )
            .then(async function (response) {
                let { status } = response.data;

                // console.log(response.data);

                if (response.data.status === 'outtime') {
                    setTimeout(() => {
                        navigate(`/`);
                    }, 1000);

                    toast.warn(response.data.msg, { theme: 'light' });
                    return;
                }

                if (status && response.data.status !== 'outtime') {
                    setBox(false);
                    setGo1(false);
                    setGo2(false);
                    setWidth(0);
                    toast.success(response.data.msg, { theme: 'light' });
                    await sleep(1000);
                    setTimeout(() => {
                        navigate(`/order/confirm/${response.data.mission.id_mission}`);
                    }, 1000);
                } else {
                    toast.warn(response.data.msg, { theme: 'light' });
                    setTimeout(() => {
                        window.location.reload();
                        // navigate(`/order/mission/${id_mission}`);
                    }, 1000);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    // console.log(data);
                    setUser(data);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (progress < 100) {
                setProgress(progress + 1);
            }
        }, 80);

        return () => clearInterval(intervalId);
    }, [progress]);

    useEffect(() => {
        checkToken();

        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/mission/my`, {
                params: {
                    id_mission: newId.current,
                },
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setMyMission(data.data);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    const upgradeMember = async () => {
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/user/upgrade`,
                { level: id_mission },
                {
                    headers,
                },
            )
            .then(async function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setUpdate(false);
                    if (data.data.type === 1) {
                        toast.success('Nâng cấp gói thành công', { theme: 'light' });
                        playAudio();
                        setTimeout(() => {
                            window.location.reload();
                        }, 1200);
                    } else {
                        toast.warn('Số dư không đủ để nâng cấp', { theme: 'light' });
                    }
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    if (!Array.isArray(myMission.missions) || myMission.missions.length <= 0) return false;

    return (
        <div className="main-container">
            <div className="sc-AjmGg kRrDOZ">
                <div className="sc-hUpaCq fvrbNa">
                    <img src={banner3} alt="Banner" />
                </div>
                <div className="textAnimate">
                    <FontAwesomeIcon className="fa-arrow-left" icon={faBullhorn} />
                    <div className="wrapper">
                        <p className="target">
                            Chương trình khuyến mãi "Gian hàng ghép đôi" thưởng ngay $66 cho mỗi cặp đôi khi đăng ký và
                            mở thành công gian hàng ghép đôi. Vui lòng liên hệ dịch vụ CSKH để tìm hiểu thêm!
                        </p>
                    </div>
                </div>
                <div
                    className="search-order"
                    onClick={() => {
                        if (myMission.type === 2) {
                            setBox(true);
                            handAnimateMission();
                        } else {
                            setUpdate(true);
                        }
                    }}
                >
                    {!box ? (
                        <button type="button" className="ant-btn ant-btn-primary ant-btn-lg sc-lcepkR hJmUWK">
                            <span>Tìm kiếm đơn hàng</span>{' '}
                        </button>
                    ) : (
                        <div
                            className="ant-btn ant-btn-primary ant-btn-lg sc-lcepkR hJmUWK progress-bar-div"
                            style={{ backgroundColor: '#fd43f0' }}
                        >
                            <button type="button" disabled>
                                <div>
                                    <span style={{ color: 'white' }}>Đang tìm kiếm đơn hàng...</span>
                                </div>
                            </button>
                            <div className="progress-bar">
                                <div className="progress-bar-text">{`${progress}%`}</div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="ant-row ant-row-space-between">
                    <div className="ant-col">
                        <div className="sc-ikJyIC gNkWet">
                            <div className="sc-jJoQJp hzrkvO">Số dư tài khoản</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(50,50,50)' }}>
                                {formatter.format(user?.data[0].money)}
                            </div>
                            <div className="sc-jJoQJp hzrkvO">Thu nhập hôm nay</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(82, 164, 120)' }}>
                                {formatter.format(user?.moneyEarn.moneyEarnToday)}
                            </div>
                        </div>
                    </div>

                    <div className="ant-col">
                        <div className="sc-ikJyIC gNkWet">
                            <div className="sc-jJoQJp hzrkvO">Số dư đang xử lý</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(183, 62, 82)' }}>
                                {formatter.format(user?.data[0].dongbangtk)}
                            </div>
                            <div className="sc-jJoQJp hzrkvO">Thu nhập hôm qua</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(160, 73, 211)' }}>
                                {formatter.format(user?.moneyEarn.moneyEarnPreDay)}
                            </div>
                        </div>
                    </div>

                    <div className="ant-col">
                        <div className="sc-ikJyIC gNkWet">
                            <div className="sc-jJoQJp hzrkvO">Gian hàng của bạn</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(50,50,50)' }}>
                                {user?.data[0].name_level}
                            </div>
                            <div className="sc-jJoQJp hzrkvO">Mức vốn đầu tư</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(82, 164, 120)' }}>
                                {formatter.format(Number(user?.data[0].price))}
                            </div>
                        </div>
                    </div>

                    <div className="ant-col">
                        <div className="sc-ikJyIC gNkWet">
                            <div className="sc-jJoQJp hzrkvO">Số đơn hoàn thành</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(50,50,50)' }}>
                                {user?.mission?.amountToday}/{user.data[0].amount}
                            </div>
                            <div className="sc-jJoQJp hzrkvO">Lợi nhuận mỗi đơn</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(82, 164, 120)' }}>
                                {Number((user?.data[0].roses).toFixed(1))}%
                            </div>
                        </div>
                    </div>
                </div>
                <div className="money-trade">MỨC VỐN ĐẦU TƯ</div>
                <div className="sc-clIzBv kHnHbZ box-home-shadow-style">
                    {mucVonDauTu.map((item, i) => {
                        return (
                            <div className="sc-cxpSdN dNsaPg" key={i}>
                                <div className="dNsaPg-detail">
                                    <p>{item.title}: </p>
                                    <span style={{ color: 'rgb(86, 177, 127)', marginLeft: '2px' }}>
                                        {' '}
                                        $ {normalFormatter.format(item.von)}
                                    </span>
                                </div>
                                <div className="dNsaPg-detail">
                                    <p>Lợi nhuận: </p>
                                    <span style={{ color: 'rgb(86, 177, 127)', marginLeft: '2px' }}>
                                        {item.loi_nhuan}%
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
        // <div className="wrapper bg-[#ef768f] min-h-[1500px] sm:min-h-[1800px] md:min-h-[2000px] lg:min-h-[2700px]">
        //     <div className={cx('main')}>
        //         <div className={cx('grab-make-money')}>
        //             <div className="w-full">
        //                 <div className={cx('grab-make-left')}>
        //                     <div className={cx('h3')}>
        //                         <font>
        //                             <font>Bộ sưu tập Kho báu</font>
        //                         </font>
        //                     </div>
        //                     <div className={cx('h3')}>
        //                         <font>
        //                             <font>Cùng nhau kiếm tiền hoa hồng</font>
        //                         </font>
        //                     </div>
        //                 </div>
        //                 <div className={cx('grab-make-right')}>
        //                     <div>
        //                         <font>
        //                             <font className="sm:text-4xl lg:text-6xl">Ngày qua ngày Kiếm tiền</font>
        //                         </font>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className={cx('grab-luck-tit')}>
        //             <span>Hôm nay vẫn còn cơ hội đặt hàng.</span>
        //         </div>
        //         <div className={cx('grab-luck-draw', { 'mb-6': true })}>
        //             <div className={cx('luck-draw-list')}>
        //                 {Array(3)
        //                     .fill({})
        //                     .map((data, index) => {
        //                         return (
        //                             <div key={index} className={cx('box')}>
        //                                 <div className={cx('li')}>
        //                                     <img
        //                                         className="w-[79px] h-[85px] sm:w-[9rem] md:w-[11rem]"
        //                                         src="https://i.imgur.com/tISDn3U.png"
        //                                         draggable="true"
        //                                         alt=""
        //                                     />
        //                                 </div>
        //                             </div>
        //                         );
        //                     })}
        //             </div>
        //         </div>
        //         <div style={{ clear: 'both' }}></div>
        //         <div className="w-[91%] mx-auto mt-[55px]">
        //             <div className={cx('orderGrabbingMain', { 'p-[16px]': true })}>
        //                 <div className="flex items-center">
        //                     <div className="relative">
        //                         {/* <div className={cx('imgLabelBox', { 'flex justify-center items-center': true })}>
        //                             CLASSIC
        //                         </div> */}
        //                         <div
        //                             className={cx('van-image', { border: true })}
        //                             style={{ width: '144px', height: '144px' }}
        //                         >
        //                             <img
        //                                 src={cocoShop}
        //                                 className="van-image__img"
        //                                 style={{ objectFit: 'contain' }}
        //                                 alt=""
        //                             />
        //                         </div>
        //                     </div>
        //                     <div className="flex-col ml-3 mb-4">
        //                         <div className="textBox mt-[6px]">
        //                             <div className="text-[#a6c4c3] text-lg">Thành viên áp dụng</div>
        //                             <div className="font-bold text-[#005652] text-xl md:text-2xl">
        //                                 {myMission.missions[0].name_level} trở lên
        //                             </div>
        //                         </div>
        //                         <div className="textBox mt-[6px]">
        //                             <div className="text-[#a6c4c3] text-lg">Giá nâng cấp gói</div>
        //                             <div className="font-bold text-[#005652] text-xl md:text-2xl">
        //                                 {formatMoney(myMission.missions[0].price)}VND
        //                             </div>
        //                         </div>
        //                         <div className="textBox mt-[6px]">
        //                             <div className="text-[#a6c4c3] text-lg">Phí hoa hồng</div>
        //                             <div className="font-bold text-[#005652] text-xl md:text-2xl">
        //                                 {myMission.missions[0].roses}% tổng số vốn đặt hàng
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div>
        //                     <p className="pt-[10px] my-2 text-center text-xl">Tài khoản của tôi.</p>
        //                     <div
        //                         style={{ color: `${myMission.type === 1 ? '#005652' : '#e74c3c'}` }}
        //                         className="flex justify-between items-center text-xl md:text-2xl bg-[#f3ffe9] mt-[10px] p-[10px]"
        //                     >
        //                         <p>cấp độ thành viên: {myMission.name_level}</p>
        //                         <FontAwesomeIcon
        //                             style={{ color: `${myMission.type === 1 ? '#2ecc71' : '#e74c3c'}` }}
        //                             className="text-3xl"
        //                             icon={myMission.type === 1 ? faCheck : faXmark}
        //                         />
        //                     </div>
        //                     <div
        //                         style={{ color: `${myMission.type === 1 ? '#005652' : '#e74c3c'}` }}
        //                         className="flex justify-between items-center text-xl md:text-2xl bg-[#f3ffe9] text-[#005652] mt-[10px] p-[10px]"
        //                     >
        //                         <p>
        //                             Giới hạn cập nhật: {myMission.amount}/{myMission.missions[0].amount}
        //                         </p>
        //                         <FontAwesomeIcon
        //                             style={{ color: `${myMission.type === 1 ? '#2ecc71' : '#e74c3c'}` }}
        //                             className="text-3xl"
        //                             icon={myMission.type === 1 ? faCheck : faXmark}
        //                         />
        //                     </div>
        //                     <div className="flex justify-between items-center text-xl md:text-2xl bg-[#f3ffe9] text-[#005652] mt-[10px] p-[10px]">
        //                         <p>số tiền còn lại: {formatMoney(myMission.money)}VND</p>
        //                         <Link className="bg-[#2ecc71] p-[5px] border rounded-md" to="/recharge">
        //                             <span className="text-[#fff]">Nạp tiền</span>
        //                         </Link>
        //                     </div>
        //                     <div
        //                         onClick={() => {
        //                             if (myMission.type === 2) {
        //                                 setBox(true);
        //                                 handAnimateMission();
        //                             } else {
        //                                 setUpdate(true);
        //                             }
        //                         }}
        //                         className="mt-[25px] text-center"
        //                     >
        //                         <button
        //                             style={{ background: `${myMission.type === 2 ? '#f53d2d' : '#3498db'}` }}
        //                             className="w-[75%] py-[10px] rounded-sm text-white"
        //                         >
        //                             {myMission.type === 2 ? 'Nhận đơn hàng' : 'Nâng cấp ngay'}
        //                             <FontAwesomeIcon
        //                                 className="ml-[7px]"
        //                                 icon={myMission.type === 2 ? faMagnifyingGlass : faBuffer}
        //                             />
        //                         </button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="w-[91%] mx-auto rounded-lg relative mt-[41px] px-[15px] py-[32px] bg-[#f8f8f8]">
        //             <div className="flex mb-[10px]">
        //                 <p className="text-2xl font-bold mr-[5px]">.</p>
        //                 <p className="text-xl">giới hạn đơn hàng hằng ngày tùy thuộc vào cấp độ của bạn. </p>
        //             </div>
        //             <div className="flex mb-[10px]">
        //                 <p className="text-2xl font-bold mr-[5px]">.</p>
        //                 <p className="text-xl">
        //                     số dư trong tài khoản của bạn phải cao hơn trị giá đơn hàng bạn định mua.{' '}
        //                 </p>
        //             </div>
        //         </div>

        //         {box && (
        //             <div>
        //                 <div className="w-[100%] h-[100%] fixed top-0 left-0 z-50 bg-[rgba(0,0,0,.7)]"></div>
        //                 <div className="fixed w-[calc(100%-30px)] top-[15%] left-[-50%] right-[-50%] mx-auto bg-[#fff] rounded-lg z-[100]">
        //                     <div className="relative van-dialog__content">
        //                         <img src="https://i.imgur.com/ktEiprH.png" alt="" />
        //                         <div className="px-[31px] py-[25px]">
        //                             <div className="mb-[25px]">
        //                                 <img
        //                                     className="w-[100px] h-[100px] mx-auto rounded-lg"
        //                                     src="https://i.imgur.com/zXB1MQd.png"
        //                                     alt=""
        //                                 />
        //                             </div>
        //                             <div className={cx('font-microsoft', { 'text-center font-bold': true })}>
        //                                 Tìm một doanh nghiệp...
        //                             </div>
        //                             <div style={{ width: `${width}%` }} className={cx('van-progress')}></div>
        //                             <div>
        //                                 <div className="flex items-center justify-between my-[15px]">
        //                                     <div
        //                                         style={{ color: `${go1 ? '#0dc253' : '#a6c4c3'}` }}
        //                                         className="text-2xl font-bold"
        //                                     >
        //                                         1. Thành lập một doanh nghiệp
        //                                     </div>
        //                                     <FontAwesomeIcon
        //                                         style={{ background: `${go1 ? '#0dc253' : '#a6c4c3'}` }}
        //                                         className="text-[#fff] border-none text-4xl  rounded-full opacity-80"
        //                                         icon={faCircleCheck}
        //                                     />
        //                                 </div>
        //                                 <div className="flex items-center justify-between my-[15px]">
        //                                     <div
        //                                         style={{ color: `${go2 ? '#0dc253' : '#a6c4c3'}` }}
        //                                         className="text-2xl font-bold"
        //                                     >
        //                                         2. Sản phẩm phù hợp
        //                                     </div>
        //                                     <FontAwesomeIcon
        //                                         style={{ background: `${go2 ? '#0dc253' : '#a6c4c3'}` }}
        //                                         className="text-[#fff] border-none text-4xl bg-[#999] rounded-full opacity-80"
        //                                         icon={faCircleCheck}
        //                                     />
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <FontAwesomeIcon
        //                             onClick={() => {
        //                                 setBox(false);
        //                                 setGo1(false);
        //                                 setGo2(false);
        //                                 setWidth(0);
        //                             }}
        //                             className="absolute text-[#fff] text-4xl left-[50%] right-[-50%] mt-[10px]"
        //                             icon={faX}
        //                         />
        //                     </div>
        //                 </div>
        //             </div>
        //         )}
        //         {update && (
        //             <div>
        //                 <div className="w-[100%] h-[100%] fixed top-0 left-0 z-50 bg-[rgba(0,0,0,.7)]"></div>
        //                 <div className="fixed w-[calc(100%-30px)] top-[30%] left-[-50%] right-[-50%] mx-auto bg-[#fff] rounded-lg z-[100]">
        //                     <div className="relative van-dialog__content">
        //                         <div className="px-[31px] py-[25px]">
        //                             <div className="mb-[25px]">
        //                                 <img
        //                                     className="w-[100px] h-[100px] mx-auto rounded-lg"
        //                                     src="https://i.imgur.com/ElwaIAc.png"
        //                                     alt=""
        //                                 />
        //                             </div>
        //                             <div className={cx('font-microsoft', { 'text-center font-bold': true })}>
        //                                 Xác nhận nâng cấp tài khoản
        //                             </div>
        //                             <div style={{ width: `${width}%` }} className={cx('van-progress')}></div>
        //                             <div className="flex justify-between">
        //                                 <div
        //                                     onClick={() => {
        //                                         setUpdate(false);
        //                                     }}
        //                                     className="w-[calc(100%)] text-center bg-[#95a5a6] mx-[15px] py-[5px] rounded-full"
        //                                 >
        //                                     <p className="text-white">Hủy</p>
        //                                 </div>
        //                                 <div
        //                                     onClick={() => {
        //                                         upgradeMember();
        //                                     }}
        //                                     className="w-[calc(100%)] text-center bg-[#3498db] mx-[15px] py-[5px] rounded-full"
        //                                 >
        //                                     <p className="text-white">Đồng ý</p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         )}
        //     </div>
        //     <ToastContainer
        //         position="top-right"
        //         autoClose={1000}
        //         hideProgressBar={false}
        //         newestOnTop={false}
        //         closeOnClick
        //         rtl={false}
        //         pauseOnFocusLoss
        //         draggable
        //         pauseOnHover
        //     />
        // </div>
    );
}

export default Order;
